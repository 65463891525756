import type { FormLabelTranslationsObject } from "../../translation-keys/form-label-keys.js";

const FormLabels: FormLabelTranslationsObject = {
eclipse_FirstName: "名前",
eclipse_LastName: "苗字",
eclipse_Email: "電子メール",
eclipse_Company: "会社名",
eclipse_Country: "国",
eclipse_Phone: "電話",
eclipse_Tier__c: "会社規模",
eclipse_Industry: "業界",
eclipse_Department__c: "部署",
eclipse_contactRequested: "電話での問い合わせを希望します。",
eclipse_Webform_Comments_recent_value__c: "どの分野をお手伝いしますか?",
eclipse_Latest_Solution_Interest__c: "ソリューションに関する主な関心",
eclipse_Email_Opt_In__c: `このトピックに関する詳細情報を受け取ることを希望し、理解および同意します。{privacy-policy-link-slot}`,
privacyStatement: `私たちはお客様のデータを尊重します。お客様のデータの処理および保護に関する当社の方針については、 {privacy-policy-link-slot}.`,
privacyPolicyLinkText: "当社のプライバシーポリシーをご覧ください。",
eclipse_Entity: "法人名",
eclipse_Partner_Opt_in_Processing__c: "このイベントで提携しているMasterControlのパートナーからこのトピックに関する詳細情報を受け取ることを希望します。",
eclipse_NonBusinessEmail: "電子メール",
privacyNewsletterOptIn: ""
};

export default FormLabels;